import logo from './logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from '@headlessui/react';
import { Cog6ToothIcon, HomeIcon, XMarkIcon, ChevronUpDownIcon, ArchiveBoxIcon, UserIcon, UsersIcon, BellAlertIcon, CreditCardIcon, ArrowRightStartOnRectangleIcon, ArrowLeftStartOnRectangleIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { useAuth } from './contexts/AuthContext';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar(props) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error("Failed to log out:", err);
    }
  };
  
  const navigation = [
    { name: 'Profile', href: '/profile', icon: UserIcon, current: props.screen === 'profile', active: true },
    { name: 'Security', href: '/security', icon: LockClosedIcon, current: props.screen === 'security', active: true },
    { name: 'Teams', href: '/teams', icon: UsersIcon, current: props.screen === 'teams', active: false },
    { name: 'Billing', href: '/billing', icon: CreditCardIcon, current: props.screen === 'billing', active: true },
    { name: 'Logout', href: '/logout', icon: ArrowLeftStartOnRectangleIcon, current: false, active: true },
  ];

  const userData = props.userData; // Use the userData prop passed from the Dashboard

  return (
    <div>
      <Dialog open={props.sidebarOpen} onClose={props.setSidebarOpen} className="relative z-40 lg:hidden">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 z-40 flex">
          <DialogPanel
            transition
            className="relative flex w-full max-w-xs flex-1 transform flex-col bg-white pb-4 pt-5 transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute right-0 top-0 -mr-12 pt-2 duration-300 ease-in-out data-[closed]:opacity-0">
                <button
                  type="button"
                  onClick={() => props.setSidebarOpen(false)}
                  className="relative ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                </button>
              </div>
            </TransitionChild>
            <div className="flex flex-shrink-0 items-center px-4">
              <img alt="Atom Copilot" src={logo} className="h-14 w-auto" />
            </div>
            <div className="mt-5 h-0 flex-1 overflow-y-auto">
              <nav className="px-2">
                <div className="space-y-1">
                  {navigation.map((item) => (
                    item.href === '/logout' ? 
                    <button
                      key={item.name}
                      onClick={handleLogout}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5 w-full',
                      )}
                    >
                      <item.icon
                        aria-hidden="true"
                        className={classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 h-6 w-6 flex-shrink-0',
                        )}
                      />
                      {item.name}
                    </button> :
                    (item.active ? 
                    <Link
                      key={item.name}
                      to={item.href}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5',
                      )}
                    >
                      <item.icon
                        aria-hidden="true"
                        className={classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 h-6 w-6 flex-shrink-0',
                        )}
                      />
                      {item.name}
                    </Link> : 
                    <div
                      key={item.name}
                      aria-current={item.current ? 'page' : undefined}
                      className={classNames(
                        item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center rounded-md px-2 py-2 text-base font-medium leading-5',
                      )}
                    >
                      <item.icon
                        aria-hidden="true"
                        className={classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 h-6 w-6 flex-shrink-0',
                        )}
                      />
                      {item.name}<span className="ml-1 text-green-600"> - Coming Soon!</span>
                    </div>)
                  ))}
                </div>
              </nav>
            </div>
          </DialogPanel>
          <div aria-hidden="true" className="w-14 flex-shrink-0"></div>
        </div>
      </Dialog>

      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-gray-100 lg:pb-4 lg:pt-5">
        <div className="flex flex-shrink-0 items-center px-6">
          <img alt="Atom Copilot" src={logo} className="h-14 w-auto" />
        </div>
        <div className="mt-5 flex h-0 flex-1 flex-col overflow-y-auto pt-1">
          <Menu as="div" className="relative inline-block px-3 text-left">
            <div>
              {!userData ? (
                <div className="flex items-center space-x-3 px-3.5 py-2">
                  <div className="h-12 w-12 bg-gray-300 rounded-full"></div>
                  <div className="flex-1 space-y-2">
                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                    <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                  </div>
                </div>
              ) : (
                <MenuButton className="group w-full rounded-md bg-gray-100 px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-gray-100">
                  <span className="flex w-full items-center justify-between">
                    <span className="flex min-w-0 items-center justify-between space-x-3">
                      {userData.profilePhoto ? (
                        <img
                          alt="Profile"
                          src={userData.profilePhoto}
                          className="h-12 w-12 flex-shrink-0 rounded-full"
                        />
                      ) : (
                        <UserCircleIcon className="h-12 w-12 text-gray-400 rounded-full" aria-hidden="true" />
                      )}
                      <span className="flex min-w-0 flex-1 flex-col">
                        <span className="truncate text-sm font-medium text-gray-900">
                          {`${userData.firstName} ${userData.lastName}`}
                        </span>
                        <span className="truncate text-sm text-gray-500">
                          {`@${userData.firstName.toLowerCase()}${userData.lastName.toLowerCase()}`}
                        </span>
                      </span>
                    </span>
                    <ChevronUpDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    />
                  </span>
                </MenuButton>
              )}
            </div>
            {userData && (
              <MenuItems
                transition
                className="absolute left-0 right-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                <div className="py-1">
                  <MenuItem>
                    <a
                      href="mailto:ananth@atomcopilot.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Download Excel Add-In
                    </a>
                  </MenuItem>
                  <MenuItem>
                    <a
                      href="mailto:support@atomcopilot.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Support
                    </a>
                  </MenuItem>
                </div>
                <div className="py-1">
                  <MenuItem>
                    <button
                      onClick={handleLogout}
                      className="w-full text-left block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    >
                      Log Out
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            )}
          </Menu>
          <nav className="mt-6 px-3">
            <div className="space-y-1">
              {navigation.map((item) => (
                item.href === '/logout' ? 
                <button
                  key={item.name}
                  onClick={handleLogout}
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium w-full',
                  )}
                >
                  <item.icon
                    aria-hidden="true"
                    className={classNames(
                      item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 h-6 w-6 flex-shrink-0',
                    )}
                  />
                  {item.name}
                </button> :
                (item.active ? 
                <Link
                  key={item.name}
                  to={item.href}
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                  )}
                >
                  <item.icon
                    aria-hidden="true"
                    className={classNames(
                      item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 h-6 w-6 flex-shrink-0',
                    )}
                  />
                  {item.name}
                </Link> : 
                <div
                  key={item.name}
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                  )}
                >
                  <item.icon
                    aria-hidden="true"
                    className={classNames(
                      item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                      'mr-3 h-6 w-6 flex-shrink-0',
                    )}
                  />
                  {item.name}<span className="ml-1 text-green-600"> - Coming Soon!</span>
                </div>
                )
              ))}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
