import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Dashboard from './dashboard';
import Profile from './profile';
import Model from './model';
import reportWebVitals from './reportWebVitals';
import Files from './files';
import Create from './create';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NewProject from './newProject';
import SignIn from './signIn';
import CreateAccount from './createAccount';
import PrivateRoute from './routes/PrivateRoute'; // Import PrivateRoute
import RedirectRoute from './routes/RedirectRoute'; // Import RedirectRoute
import RedirectAuthenticatedRoute from './routes/RedirectAuthenticatedRoute'
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider
import ProjectSettings from './projectSettings';
import Archive from './archive';
import Billing from './billing';
import Security from './security';
import Team from './team';
import VerifyEmail from './verifyEmail';
import VerifyEmailRoute from './routes/VerifyEmailRoute';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <Routes>
          <Route 
            path="/" 
            element={
              <RedirectAuthenticatedRoute>
                <SignIn />
              </RedirectAuthenticatedRoute>
            } 
          />
          <Route 
            path="/create-account" 
            element={
              <RedirectAuthenticatedRoute>
                <CreateAccount />
              </RedirectAuthenticatedRoute>
            } 
          />
          <Route 
            path="/verify-email" 
            element={
              <VerifyEmailRoute>
                <VerifyEmail />
              </VerifyEmailRoute>
            } 
          />
          {/*
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/archive"
            element={
              <PrivateRoute>
                <Archive />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-project"
            element={
              <PrivateRoute>
                <NewProject />
              </PrivateRoute>
            }
          />
          <Route
            path="/projects/:projectId/models"
            element={
              <PrivateRoute>
                <Model />
              </PrivateRoute>
            }
          />
          <Route
            path="/projects/:projectId/files"
            element={
              <PrivateRoute>
                <Files />
              </PrivateRoute>
            }
          />
          <Route
            path="/projects/:projectId/settings"
            element={
              <PrivateRoute>
                <ProjectSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/projects/:projectId/models/:modelId/edit"
            element={
              <PrivateRoute>
                <Create />
              </PrivateRoute>
            }
          />
          */}
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/security"
            element={
              <PrivateRoute>
                <Security />
              </PrivateRoute>
            }
          />
          <Route
            path="/billing"
            element={
              <PrivateRoute>
                <Billing />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<RedirectRoute />} />
        </Routes>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
