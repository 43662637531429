import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { db } from './firebase';
import { doc, getDoc } from "firebase/firestore";
import { useAuth } from './contexts/AuthContext';
import Navbar from './navbar';
import MobileNav from './mobile';
import Notifications from './notifications';
import Header from './header';
import {
  EllipsisVerticalIcon,
  ArrowsRightLeftIcon,  // Replace Icon
  TrashIcon,            // Delete Icon
} from '@heroicons/react/24/outline';
import Spinner from './spinner'; // Assuming you have a spinner component
import { formatDistanceToNow } from 'date-fns';

const fileTypes = [
  "Balance Sheet",
  "Income Statement",
  "Cash Flow",
  "Tax Returns",
  "Equity",
  "Budgets",
  "Profit and Loss",
  "General Ledger",
  "Accounts Receivable",
  "Accounts Payable",
  "Bank Statements"
];

const transactions = [
  { id: 1, name: 'BSheet.xlsx', type: 'Balance Sheet', uploadTime: new Date() - 180000 },
  { id: 2, name: 'IS.xlsx', type: 'Income Statement', uploadTime: new Date() - 1080000 },
  { id: 3, name: 'CFS.xlsx', type: 'Cash Flow', uploadTime: new Date() - 2700000 },
  { id: 4, name: 'TR.xlsx', type: 'Tax Returns', uploadTime: new Date() - 3600000 },
  { id: 5, name: 'E.xlsx', type: 'Equity', uploadTime: new Date() - 7200000 },
  { id: 6, name: 'B.xlsx', type: 'Budgets', uploadTime: new Date() - 7200000 },
];

export default function Files() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [reloadTrigger] = useState(false);
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [userData, setUserData] = useState(null); // State to hold user data
  const { currentUser } = useAuth();
  const { projectId } = useParams();
  const [menuOpen, setMenuOpen] = useState({});

  useEffect(() => {
    document.title = project ? `${project.title} Files - Atom Copilot | AI-driven Financial Modeling Tool` : 'Files - Atom Copilot | AI-driven Financial Modeling Tool';
  }, [project]);

  useEffect(() => {
    // Fetch user data and project data concurrently
    const fetchData = async () => {
      if (currentUser) {
        try {
          // Fetch user data for the Navbar and other components
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            setUserData(userDocSnap.data());
          }

          // Fetch the specific project data using the project ID
          if (projectId) {
            const projectDocRef = doc(db, "projects", projectId);
            const projectDocSnap = await getDoc(projectDocRef);
            if (projectDocSnap.exists()) {
              setProject(projectDocSnap.data());
            } else {
              console.error("No such project found!");
            }
          }
        } catch (err) {
          console.error("Error fetching data:", err);
        } finally {
          setTimeout(() => {
            setIsLoading(false); // Stop loading after data is fetched
          }, 300); // Add a slight delay for smoother transition
        }
      }
    };

    fetchData();
  }, [currentUser, projectId]);

  const toggleMenu = (id) => {
    setMenuOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    isLoading ? (
      <Spinner />
    ) : (
      <>
        <div className="min-h-full">
          <Navbar 
            screen="model" 
            sidebarOpen={sidebarOpen} 
            setSidebarOpen={setSidebarOpen} 
            reloadTrigger={reloadTrigger} 
            userData={userData} // Pass user data to Navbar
          />
          <div className="flex flex-col lg:pl-64">
            <MobileNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} reloadTrigger={reloadTrigger} />
            <div className="h-full">
              <Header text={"Project Files"} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />

              {/* Archive Signal */}
              {project?.archived && (
                <div className="mx-4 sm:mx-6 lg:mx-8 mt-6 bg-yellow-100 text-yellow-800 px-4 py-3 rounded-md relative" role="alert">
                  <strong className="font-bold">Archived!</strong>
                  <span className="block sm:inline"> This project is currently archived. You can still view and edit details, but the project is not active.</span>
                </div>
              )}

              <main className="col-span-2 lg:col-span-5 flex-1 mr-4">
                <div className="mt-6 px-4 sm:px-6 lg:px-8">
                  {project ? (
                    <>
                      <div className="min-w-0 flex-1">
                        <h1 className="text-xl font-semibold leading-6 text-gray-900 sm:truncate">{project.title}</h1>
                      </div>
                      <div className="mt-2 min-w-0 flex-1">
                        <h1 className="text-base font-medium leading-6 text-gray-600">{project.description}</h1>
                      </div>
                    </>
                  ) : (
                    <p>Loading project details...</p>
                  )}
                  <div className="mt-4 flex items-center justify-between">
                    <div className="flex sm:flex sm:items-start sm:justify-start gap-4">
                      <Link
                        to={`/projects/${projectId}/models`}
                        className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 order-0 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 sm:order-1"
                      >
                        Models
                      </Link>
                      <button
                        className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 order-0 rounded-md px-3 py-2 text-sm font-bold bg-gray-200 text-gray-700 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 sm:order-1"
                      >
                        Files
                      </button>
                      <Link
                        to={`/projects/${projectId}/settings`}
                        className="text-gray-600 hover:bg-gray-100 hover:text-gray-900 order-0 rounded-md px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 sm:order-1"
                      >
                        Settings
                      </Link>
                    </div>
                    <Link 
                      to="/dashboard"
                      className="text-gray-600 hover:text-gray-900 text-sm font-semibold">
                        &larr; Back to Home
                    </Link>
                  </div>
                  {/* Mobile Activity List */}
                  <div className="my-6 py-5 shadow sm:hidden border border-gray-200 bg-gray-200 rounded-lg drop-shadow-lg">
                    <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                      {transactions.map((file) => (
                        <li key={file.id} className="relative">
                          <div className="block bg-white px-4 py-4 hover:bg-gray-50">
                            <span className="flex items-center space-x-4">
                              <span className="flex flex-1 space-x-2 truncate">
                                <span className="flex flex-col truncate text-sm text-gray-700">
                                  <span className="truncate">{file.name}</span>
                                  <select className="shadow-sm rounded-md border border-gray-200 bg-transparent focus:ring-0 text-sm text-gray-600 my-2">
                                    {fileTypes.map((type) => (
                                      <option key={type} value={type} selected={type === file.type}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                  <time dateTime={file.uploadTime}>
                                    {formatDistanceToNow(new Date(file.uploadTime), { addSuffix: true })}
                                  </time>
                                </span>
                              </span>
                              <button onClick={() => toggleMenu(file.id)} className="text-gray-400 hover:text-gray-600">
                                <EllipsisVerticalIcon className="h-5 w-5" />
                              </button>
                            </span>
                          </div>
                          {menuOpen[file.id] && (
                            <div className="absolute right-4 mt-2 w-40 bg-white border rounded-md shadow-lg">
                              <button className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                <ArrowsRightLeftIcon className="h-5 w-5 mr-2" />
                                Replace
                              </button>
                              <button className="flex items-center px-4 py-2 text-sm text-red-700 hover:bg-red-100">
                                <TrashIcon className="h-5 w-5 mr-2" />
                                Delete
                              </button>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="py-5 hidden sm:block">
                    <div className="mt-2 flex flex-col">
                      <div className="min-w-full overflow-hidden overflow-x-auto border border-gray-100 align-middle rounded-lg drop-shadow-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="bg-gray-50 px-6 py-3 text-left text-sm font-medium text-gray-700"
                              >
                                File
                              </th>
                              <th
                                scope="col"
                                className="bg-gray-50 px-6 py-3 text-left text-sm font-medium text-gray-700"
                              >
                                File Type
                              </th>
                              <th
                                scope="col"
                                className="bg-gray-50 px-6 py-3 text-right text-sm font-medium text-gray-700"
                              >
                                Upload Time
                              </th>
                              <th
                                scope="col"
                                className="bg-gray-50 px-6 py-3 text-right text-sm font-medium text-gray-700"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {transactions.map((file) => (
                              <tr key={file.id} className="bg-white">
                                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                  {file.name}
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                  <select className="shadow-sm rounded-md border border-gray-200 text-sm bg-transparent focus:ring-0">
                                    {fileTypes.map((type) => (
                                      <option key={type} value={type} selected={type === file.type}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-700">
                                  {formatDistanceToNow(new Date(file.uploadTime), { addSuffix: true })}
                                </td>
                                <td className="relative whitespace-nowrap px-6 py-4 text-right text-sm">
                                  <button onClick={() => toggleMenu(file.id)} className="text-gray-400 hover:text-gray-600">
                                    <EllipsisVerticalIcon className="h-5 w-5" />
                                  </button>
                                  {menuOpen[file.id] && (
                                    <div className="absolute z-20 right-8 mt-2 w-32 bg-white border rounded-md shadow-lg">
                                      <button className="flex justify-start items-center w-full px-2 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                        <ArrowsRightLeftIcon className="h-5 w-5 mr-2" />
                                        Replace
                                      </button>
                                      <button className="flex justify-start items-center w-full px-2 py-2 text-sm text-red-700 hover:bg-red-100">
                                        <TrashIcon className="h-5 w-5 mr-2" />
                                        Delete
                                      </button>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
              <Notifications reloadTrigger={reloadTrigger} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </div>
          </div>
        </div>
      </>
    )
  );
}
