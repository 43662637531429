// src/verifyEmail.js
import React, { useEffect, useState } from 'react';
import logo from './logo.png';
import Spinner from './spinner';
import { useAuth } from './contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function VerifyEmail() {
  const { currentUser, isEmailVerified, logout } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Verify Email - Atom Copilot | AI-driven Financial Modeling Tool';
  }, []);

  useEffect(() => {
    if (isEmailVerified) {
      navigate('/verify-email');
    }
  }, [isEmailVerified])

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error("Failed to log out:", err);
    }
  };

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-10">
          <img
            alt="Atom Copilot"
            src={logo}
            className="mx-auto h-20 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Verify Your Email
          </h2>
          <h2 className="mt-6 text-center text-base text-gray-700">
            Click the link sent to {currentUser.email} to verify your Atom Copilot account. If you have already verified your email, please refresh the page.
          </h2>
          <div className="items-center flex justify-center mt-10">
            <div className=" loader border-t-4 border-green-600 border-solid rounded-full w-24 h-24 animate-spin"></div>
          </div>
          <div className="items-center flex justify-center mt-32">
            <button
              onClick={handleLogout}
              className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Log out of {currentUser.email}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
