// src/routes/RedirectRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function VerifyEmailRoute({ children }) {
  const { currentUser, isEmailVerified } = useAuth();

  return currentUser ?
    (isEmailVerified ?
      <Navigate to="/profile" /> : 
      children
    )
    : <Navigate to="/" />;
}
