// src/SignIn.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import { useAuth } from './contexts/AuthContext';
import ErrorMessage from './ErrorMessage';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

export default function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetError, setResetError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const { login, resetPassword, createAccountWithGoogle, createAccountWithMicrosoft } = useAuth();

  useEffect(() => {
    document.title = "Sign In - Atom Copilot | AI-driven Financial Modeling Tool";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate('/dashboard');
    } catch (err) {
      setError('Failed to log in. Please check your credentials.');
    }
  };

  const handleResetPassword = async () => {
    setSuccessMessage('');
    try {
      await resetPassword(resetEmail);
      setSuccessMessage('Password reset email sent! Check your inbox.');
      setResetError('');
    } catch (err) {
      setResetError('Failed to send reset email. Please check the email address.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await createAccountWithGoogle();
      navigate('/profile');
    } catch (err) {
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  const handleMicrosoftSignIn = async () => {
    try {
      await createAccountWithMicrosoft();
      navigate('/profile');
    } catch (err) {
      setError('Failed to sign in with Microsoft. Please try again.');
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setSuccessMessage('');
      setResetError('');
      setResetEmail('');
    }, 500);
  };

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Atom Copilot"
            src={logo}
            className="mx-auto h-20 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Log in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <button
                    type="button"
                    onClick={() => setIsModalOpen(true)}
                    className="font-semibold text-green-600 hover:text-green-600"
                  >
                    Forgot password?
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            {error && (
              <ErrorMessage text={error} />
            )}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Sign in
              </button>
            </div>
          </form>
          <div>
            <div className="relative mt-10">
              <div aria-hidden="true" className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">Or continue with</span>
              </div>
            </div>

            <div className="mt-4 grid grid-cols-2 gap-4">
              {/* Google Sign In Button */}
              <button
                onClick={handleGoogleSignIn}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent cursor-pointer"
              >
                <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
                  {/* Google SVG paths */}
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
                <span className="text-sm font-semibold leading-6">Google</span>
              </button>

              {/* Microsoft Sign In Button */}
              <button
                onClick={handleMicrosoftSignIn}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent cursor-pointer"
              >
                <svg viewBox="0 0 48 48" aria-hidden="true" className="h-5 w-5">
                  <rect x="2" y="2" width="20" height="20" fill="#F35325"></rect>
                  <rect x="26" y="2" width="20" height="20" fill="#81BC06"></rect>
                  <rect x="2" y="26" width="20" height="20" fill="#05A6F0"></rect>
                  <rect x="26" y="26" width="20" height="20" fill="#FFBA08"></rect>
                </svg>
                <span className="text-sm font-semibold leading-6">Microsoft</span>
              </button>
            </div>
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <Link to="/create-account" className="font-semibold leading-6 text-green-600 hover:text-green-600">
              Create an account
            </Link>
          </p>
        </div>
      </div>

      {/* Reset Password Modal with smooth transition */}
      <Dialog open={isModalOpen} onClose={() => closeModal()} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div className="fixed inset-0 z-10 flex items-center justify-center p-4">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white p-6 text-center shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-md data-[closed]:sm:scale-95"
          >
            <div className="flex justify-center mb-4">
              <div className="h-14 w-14 flex items-center justify-center rounded-full bg-green-100">
                <EnvelopeIcon className="text-green-600 h-8 w-8" />
              </div>
            </div>
            <DialogTitle className="text-lg font-medium text-gray-900">Reset password</DialogTitle>
            <p className="mt-2 text-sm text-gray-500">
              An email with instructions on how to reset your password will be sent to that email.
            </p>
            <div className="mt-4">
              <input
                type="email"
                placeholder="Enter your email here"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                className="text-sm w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
              />
            </div>
            {resetError && <div className="mt-4"><ErrorMessage text={resetError} /></div>}
            {successMessage && (
              <p className="mt-2 text-sm text-green-600">{successMessage}</p>
            )}
            <button
              onClick={handleResetPassword}
              className="text-sm mt-4 w-full rounded-md bg-green-600 py-2 text-white font-semibold shadow-sm hover:bg-green-500 focus:outline-none"
            >
              Send reset email
            </button>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
