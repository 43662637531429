import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { db } from './firebase';
import { useAuth } from './contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';

export default function Notifications({ reloadTrigger, drawerOpen, setDrawerOpen }) {
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          const sortedNotifications = (userData.notifications || []).sort((a, b) => {
            return new Date(b.timestamp) - new Date(a.timestamp);
          });
          const recentNotifications = sortedNotifications.slice(0, 6);
          setNotifications(recentNotifications);
          setLoading(false);
        }
      }
    };

    fetchNotifications();
  }, [currentUser, reloadTrigger]);

  return (
    <Dialog open={drawerOpen} onClose={setDrawerOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-xs transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="ml-2 sm:ml-0 text-xl font-semibold leading-6 text-gray-700">
                      Notifications
                    </DialogTitle>
                    <button
                      type="button"
                      onClick={() => setDrawerOpen(false)}
                      className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="absolute -inset-2.5" />
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                </div>
                <div className="ml-2 sm:ml-0 relative mt-6 flex-1 px-4 sm:px-6">
                  <ul className="divide-y divide-gray-200">
                    {loading ? (
                      Array.from({ length: 6 }).map((_, index) => (
                        <li key={index} className="py-4">
                          <div className="animate-pulse flex space-x-3">
                            <div className="h-6 w-6 bg-gray-300 rounded-full" />
                            <div className="flex-1 space-y-2">
                              <div className="h-4 bg-gray-300 rounded w-1/2"></div>
                              <div className="h-3 bg-gray-200 rounded w-1/3"></div>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      notifications.map((item) => (
                        <li key={item.id} className="py-4">
                          <div className="flex space-x-3">
                            <BellIcon
                              alt=""
                              className="h-6 w-6 rounded-full text-gray-500"
                            />
                            <div className="flex-1 space-y-1">
                              <div className="flex items-center justify-between">
                                <h3 className="text-sm font-medium text-gray-700">{item.title}</h3>
                              </div>
                              <p className="text-sm text-green-600">
                                {new Date(item.timestamp).toLocaleString()}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))
                    )}
                  </ul>
                  {!loading && notifications.length === 0 && (
                    <div className="w-full text-gray-700 text-center mt-8 mb-10">
                      No notifications yet.
                    </div>
                  )}
                  <div className="py-6 border-t border-gray-200 py-4 text-sm">
                    <Link to="/profile" className="font-semibold text-gray-700 hover:text-gray-800">
                      Go to Settings <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
