import {
  BellIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'

export default function Header(props) {
  return (
    <header className="flex items-center justify-between p-4 bg-white shadow-sm border-b border-gray-100 px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-semibold text-gray-900">{props.text}</h1>
      <div className="flex items-center space-x-4">
        <a 
          href="https://atomcopilot.com/"
          target="_blank"
          rel="noreferrer"
          className="p-2 rounded-full bg-white hover:bg-gray-100 focus:outline-none"
        >
          <InformationCircleIcon className="h-6 w-6 text-gray-800" aria-hidden="true" />
        </a>
        {/* Bell Icon to open notifications drawer */}
        <button
          onClick={() => props.setDrawerOpen(!props.drawerOpen)}
          className="p-2 rounded-full bg-white hover:bg-gray-100 focus:outline-none"
        >
          <BellIcon className="h-6 w-6 text-gray-800" aria-hidden="true" />
        </button>
      </div>
    </header>
  )
}