import { useState, useEffect } from 'react';
import { UserCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import Navbar from './navbar';
import MobileNav from './mobile';
import Notifications from './notifications';
import { useAuth } from './contexts/AuthContext';
import { db, storage } from './firebase';
import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Spinner from './spinner';

export default function Profile() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [file, setFile] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const { currentUser, logout } = useAuth();
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    document.title = "Profile - Atom Copilot | AI-driven Financial Modeling Tool";
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true); // Start loading
      console.log(currentUser)
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setEmail(data.email || '');
          setProfilePhoto(data.profilePhoto || null);
          setLastUpdated(data.lastUpdated ? new Date(data.lastUpdated).toLocaleString() : '');
          setLoaded(true)
        } else {
          setTimeout(() => {
            fetchUserData()
            return
          }, 1000); 
        }
      }
      setTimeout(() => {
        setIsLoading(false)
      }, 1100); // Stop loading with a slight delay for smooth transition
    };
    fetchUserData();
  }, [currentUser]);

  const handleProfilePhotoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setProfilePhoto(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const handleRemoveProfilePhoto = () => {
    setProfilePhoto(null);
    setFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);

        let profilePhotoURL = profilePhoto;
        if (file) {
          const storageRef = ref(storage, `profilePhotos/${currentUser.uid}`);
          await uploadBytes(storageRef, file);
          profilePhotoURL = await getDownloadURL(storageRef);
        }

        const updateTimestamp = new Date().toISOString();

        await updateDoc(userDocRef, {
          firstName,
          lastName,
          email,
          profilePhoto: profilePhotoURL || '',
          lastUpdated: updateTimestamp,
          notifications: arrayUnion({
            id: Date.now().toString(),
            title: "Profile Updated",
            message: "Your profile has been successfully updated.",
            timestamp: updateTimestamp,
            read: false
          })
        });

        setLastUpdated(new Date(updateTimestamp).toLocaleString());
        setReloadTrigger(!reloadTrigger);
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
      }
    } catch (err) {
      console.error("Error updating profile: ", err);
    }
  };

  return (
    isLoading ? (
      <Spinner />
    ) : (
      <>
        <div className="min-h-full">
          <Navbar 
            screen="profile" 
            sidebarOpen={sidebarOpen} 
            setSidebarOpen={setSidebarOpen} 
            reloadTrigger={reloadTrigger} 
            userData={{ firstName, lastName, email, profilePhoto }} // Pass user data to Navbar
          />
          <div className="flex flex-col lg:pl-64">
            <MobileNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} reloadTrigger={reloadTrigger} />
            <div className="h-full">
              <Header text="Profile" drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
              <main className="col-span-2 flex-1 overflow-y-auto mr-4">
                <div className="flex-1 xl:overflow-y-auto mb-10">
                  <div className="mt-6 px-4 sm:px-6 lg:px-8">
                    <form onSubmit={handleSubmit}>
                      {/* Profile settings form */}
                      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-6 sm:gap-x-6">
                        {/* First Name */}
                        <div className="sm:col-span-3">
                          <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-slate-900">
                            First name
                          </label>
                          <input
                            id="first-name"
                            name="first-name"
                            type="text"
                            autoComplete="given-name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-green-300 sm:text-sm sm:leading-6"
                          />
                        </div>

                        {/* Last Name */}
                        <div className="sm:col-span-3">
                          <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-slate-900">
                            Last name
                          </label>
                          <input
                            id="last-name"
                            name="last-name"
                            type="text"
                            autoComplete="family-name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-green-300 sm:text-sm sm:leading-6"
                          />
                        </div>
                        {/* Profile Photo */}
                        <div className="sm:col-span-6">
                          <label htmlFor="photo" className="block text-sm font-medium leading-6 text-slate-900">
                            Photo
                          </label>
                          <div className="mt-2 flex items-center">
                            {profilePhoto ? (
                              <img
                                alt=""
                                src={profilePhoto}
                                className="inline-block h-12 w-12 rounded-full"
                              />
                            ) : (
                              <UserCircleIcon className="h-12 w-12 text-gray-400 rounded-full" aria-hidden="true" />
                            )}
                            <div className="relative ml-4">
                              <input
                                id="user-photo"
                                name="user-photo"
                                type="file"
                                onChange={handleProfilePhotoChange}
                                className="peer absolute inset-0 h-full w-full rounded-md opacity-0"
                              />
                              <label
                                htmlFor="user-photo"
                                className="pointer-events-none block rounded-md bg-white px-3 py-2 text-sm font-semibold text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 peer-hover:bg-slate-50 peer-focus:ring-2 peer-focus:ring-green-600"
                              >
                                <span>Change</span>
                                <span className="sr-only"> user photo</span>
                              </label>
                            </div>
                            <button
                              type="button"
                              onClick={handleRemoveProfilePhoto}
                              className="ml-6 text-sm font-medium leading-6 text-slate-900"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Action Buttons */}
                      <div className="flex justify-between gap-x-3 pt-10">
                        <div className="flex space-x-3">
                          <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 gap-y-6 pt-2 sm:grid-cols-6 sm:gap-x-6">
                        <p className="text-sm text-slate-500 sm:col-span-6">
                          Profile last updated on{' '}
                          <time dateTime={lastUpdated ? new Date(lastUpdated).toISOString() : ''}>{lastUpdated}</time>.
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </main>

              {/* Notifications Section */}
              <Notifications reloadTrigger={reloadTrigger} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </div>
          </div>
        </div>

        {/* Success message */}
        {showSuccessMessage && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-lg flex items-center" role="alert">
            <CheckCircleIcon className="w-5 h-5 text-green-700 mr-2" />
            <span>Profile updated successfully!</span>
          </div>
        )}
      </>
    )
  );
}
