import { useState, useEffect } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { EnvelopeIcon } from '@heroicons/react/24/outline'; // Import envelope icon for email sign-in method
import Navbar from './navbar';
import MobileNav from './mobile';
import Notifications from './notifications';
import { useAuth } from './contexts/AuthContext';
import { db } from './firebase';
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Spinner from './spinner';

export default function Security() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [twoStepVerification, setTwoStepVerification] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('');
  const [lastUpdated, setLastUpdated] = useState('');
  const { currentUser, logout, resetPassword } = useAuth();
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profilePhoto: null,
    signInMethod: '', // Add sign-in method state
  });

  useEffect(() => {
    document.title = "Security - Atom Copilot | AI-driven Financial Modeling Tool";
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true)
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setEmail(data.email || '');
          setTwoStepVerification(data.twoStepVerification || false);
          setLastUpdated(data.lastUpdated ? new Date(data.lastUpdated).toLocaleString() : '');

          setUserData({
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            email: data.email || '',
            profilePhoto: data.profilePhoto || null,
            signInMethod: data.signInMethod || 'email', // Set sign-in method from the database, default to email
          });
        } else {
          console.error("No user data found.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const updateTimestamp = new Date().toISOString();

        await updateDoc(userDocRef, {
          twoStepVerification,
          lastUpdated: updateTimestamp,
        });

        setLastUpdated(new Date(updateTimestamp).toLocaleString());
        setReloadTrigger(!reloadTrigger);
        setSuccessMessage('Security settings saved successfully!');
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          setSuccessMessage('');
        }, 2000);
      }
    } catch (err) {
      console.error("Error updating security settings: ", err);
    }
  };

  const handleResetPassword = async () => {
    try {
      await resetPassword(email);
      setSuccessMessage('Password reset email sent! Check your inbox.');
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        setSuccessMessage('');
      }, 2000);
    } catch (err) {
    }
  };
  
  // SVG icons for Google, Microsoft, and Email sign-in methods
  const signInIcons = {
    google: (
      <svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6">
        <path d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z" fill="#EA4335" />
        <path d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z" fill="#4285F4" />
        <path d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z" fill="#FBBC05" />
        <path d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z" fill="#34A853" />
      </svg>
    ),
    microsoft: (
      <svg viewBox="0 0 48 48" aria-hidden="true" className="h-6 w-6">
        <rect x="2" y="2" width="20" height="20" fill="#F35325"></rect>
        <rect x="26" y="2" width="20" height="20" fill="#81BC06"></rect>
        <rect x="2" y="26" width="20" height="20" fill="#05A6F0"></rect>
        <rect x="26" y="26" width="20" height="20" fill="#FFBA08"></rect> 
      </svg>
    ),
    email: <EnvelopeIcon className="h-6 w-6 text-gray-900" />,
  };

  return (
    isLoading ? (
      <Spinner />
    ) : (
      <>
        <div className="min-h-full">
          <Navbar 
            screen="security" 
            sidebarOpen={sidebarOpen} 
            setSidebarOpen={setSidebarOpen} 
            reloadTrigger={reloadTrigger} 
            userData={userData} 
          />
          <div className="flex flex-col lg:pl-64">
            <MobileNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} reloadTrigger={reloadTrigger} />
            <div className="h-full">
              <Header text="Security" drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
              <main className="col-span-2 flex-1 overflow-y-auto mr-4">
                <div className="flex-1 xl:overflow-y-auto mb-10">
                  <div className="mt-6 px-4 sm:px-6 lg:px-8">
                    {errorMessage && (
                      <div className="mb-4 flex items-center rounded-lg bg-red-100 border border-red-400 text-red-700 px-4 py-3" role="alert">
                        <XCircleIcon className="w-5 h-5 text-red-700 mr-2" />
                        <span>{errorMessage}</span>
                      </div>
                    )}
                    
                    {/* Sign-In Method Display */}
                    {userData.signInMethod !== 'email' &&
                    <div className="flex items-center mb-6">
                      {signInIcons[userData.signInMethod.toLowerCase()]}
                      <span className="ml-2 text-md font-medium text-gray-900">
                        Signed-in with {userData.signInMethod.charAt(0).toUpperCase() + userData.signInMethod.slice(1)}
                      </span>
                    </div>}

                    <form onSubmit={handleSubmit}>
                      <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                        {/* Email Address */}
                        <div className="sm:col-span-5">
                          <label htmlFor="email-address" className="block text-sm font-medium leading-6 text-slate-900">
                            Email address
                          </label>
                          <input
                            id="email-address"
                            name="email-address"
                            type="email"
                            autoComplete="email"
                            value={email}
                            readOnly={true} // Read-only if not email sign-in
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-green-300 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      {/* Action Buttons */}
                      {userData.signInMethod === 'email' &&
                      <div className="flex justify-between gap-x-3 pt-10">
                        <button
                          onClick={handleResetPassword}
                          className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          Reset Password
                        </button>
                      </div>}

                      <div className="grid grid-cols-1 gap-y-6 pt-2 sm:grid-cols-6 sm:gap-x-6">
                        <p className="text-sm text-slate-500 sm:col-span-6">
                          Security settings last updated on{' '}
                          <time dateTime={lastUpdated ? new Date(lastUpdated).toISOString() : ''}>{lastUpdated}</time>.
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </main>

              {/* Notifications Section */}
              <Notifications reloadTrigger={reloadTrigger} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            </div>
          </div>
        </div>

        {/* Success message */}
        {showSuccessMessage && (
          <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-lg flex items-center" role="alert">
            <CheckCircleIcon className="w-5 h-5 text-green-700 mr-2" />
            <span>{successMessage}</span>
          </div>
        )}
      </>
    )
  );
}
