import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/react';
import { Bars3CenterLeftIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { useState, useEffect } from 'react';
import { db } from './firebase';
import { doc, getDoc } from "firebase/firestore";

export default function MobileNav({ setSidebarOpen, reloadTrigger }) {
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData(docSnap.data());
        } else {
          console.error("No such document!");
        }
      }
    };
    fetchUserData();
  }, [currentUser, reloadTrigger]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error("Failed to log out:", err);
    }
  };

  return (
    <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:hidden">
      <button
        type="button"
        onClick={() => setSidebarOpen(true)}
        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3CenterLeftIcon aria-hidden="true" className="h-6 w-6" />
      </button>
      <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex flex-1">
        </div>
        <div className="flex items-center">
          <Menu as="div" className="relative ml-3">
            <div>
              <MenuButton className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                <span className="absolute -inset-1.5" />
                <span className="sr-only">Open user menu</span>
                {userData && userData.profilePhoto ? (
                  <img
                    alt="Profile"
                    src={userData.profilePhoto}
                    className="h-8 w-8 rounded-full"
                  />
                ) : (
                  <UserCircleIcon className={`h-10 w-10 rounded-full ${!userData ? 'animate-pulse text-gray-300' : 'text-gray-400'}`} aria-hidden="true" />
                )}
              </MenuButton>
            </div>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1 px-4">
                <div className="text-sm font-medium text-gray-900">
                  {userData ? `${userData.firstName} ${userData.lastName}` : (
                    <div className="animate-pulse bg-gray-300 h-4 w-24 rounded-md"></div>
                  )}
                </div>
                <div className="text-sm text-gray-500">
                  {userData ? `@${userData.firstName.toLowerCase()}${userData.lastName.toLowerCase()}` : (
                    <div className="animate-pulse bg-gray-200 h-4 w-20 mt-1 rounded-md"></div>
                  )}
                </div>
              </div>
              <div className="py-1">
                <MenuItem>
                  <a
                    href="mailto:ananth@atomcopilot.com"
                    target="_blank" rel="noopener noreferrer"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    Download Excel Add-In
                  </a>
                </MenuItem>
                <MenuItem>
                  <a
                    href="mailto:support@atomcopilot.com"
                    target="_blank" rel="noopener noreferrer"
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    Support
                  </a>
                </MenuItem>
              </div>
              <div className="py-1">
                <MenuItem>
                  <button
                    onClick={handleLogout}
                    className="w-full text-left block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                  >
                    Log Out
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  );
}
