// src/CreateAccount.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.png';
import { useAuth } from './contexts/AuthContext';
import ErrorMessage from './ErrorMessage';
import { db } from './firebase'; // Import Firestore
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore functions

export default function CreateAccount() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [twoStepVerification, setTwoStepVerification] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const {
    signup,
    createAccountWithGoogle,
    createAccountWithMicrosoft,
  } = useAuth();

  useEffect(() => {
    document.title = 'Create Account - Atom Copilot | AI-driven Financial Modeling Tool';
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const userCredential = await signup(email, password);
      const user = userCredential.user;

      // Store additional user information in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        firstName: firstName,
        lastName: lastName,
        email: email,
        profilePhoto: '', // Placeholder for profile photo URL
        twoStepVerification: twoStepVerification, // Store two-step verification status
        signInMethod: 'email', // Track sign-in method
        notifications: [
          {
            id: Date.now().toString(), // Generate a unique ID based on the current timestamp
            title: 'Account Created',
            message: 'Your account has been successfully created.',
            timestamp: new Date().toISOString(),
            read: false,
          },
        ], // Add the "account created" notification
        lastUpdated: new Date().toISOString(), // Add the last updated timestamp
      });

      navigate('/verify-email');
    } catch (err) {
      setError('Sorry, we could not create your account');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await createAccountWithGoogle();
      navigate('/profile');
    } catch (err) {
      setError(err.message || 'Failed to create an account with Google.');
    }
  };

  const handleMicrosoftSignIn = async () => {
    try {
      await createAccountWithMicrosoft();
      navigate('/profile');
    } catch (err) {
      setError(err.message || 'Failed to create an account with Microsoft.');
    }
  };

  return (
    <>
      <div className="flex min-h-screen flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Atom Copilot"
            src={logo}
            className="mx-auto h-20 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create an account
          </h2>
        </div>

        <div className="mt-4 sm:mx-auto w-1/2">
          <form onSubmit={handleSubmit}>
            <div className="space-y-8">
              <div className="border-b border-gray-900/10 pb-6">
                <div className="mt-4 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        id="first-name"
                        name="first-name"
                        type="text"
                        autoComplete="given-name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        id="last-name"
                        name="last-name"
                        type="text"
                        autoComplete="family-name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                      Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">
                      Confirm Password
                    </label>
                    <div className="mt-2">
                      <input
                        id="confirm-password"
                        name="confirm-password"
                        type="password"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Create Account
              </button>
            </div>
            <div className="mt-6">
              {error && <ErrorMessage text={error} />}
            </div>
          </form>

          <div className="relative mt-10">
            <div aria-hidden="true" className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">Or continue with</span>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-2 gap-4">
            {/* Google Sign In Button */}
            <a
              onClick={handleGoogleSignIn}
              className="col-span-2 sm:col-span-1 flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent cursor-pointer"
            >
              <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
                {/* Google SVG paths */}
                <path
                  d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                  fill="#EA4335"
                />
                <path
                  d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                  fill="#4285F4"
                />
                <path
                  d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                  fill="#FBBC05"
                />
                <path
                  d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                  fill="#34A853"
                />
              </svg>
              <span className="text-sm font-semibold leading-6">Google</span>
            </a>

            {/* Microsoft Sign In Button */}
            <a
              onClick={handleMicrosoftSignIn}
              className="col-span-2 sm:col-span-1 flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent cursor-pointer"
            >
              <svg viewBox="0 0 48 48" aria-hidden="true" className="h-5 w-5">
                <rect x="2" y="2" width="20" height="20" fill="#F35325"></rect>
                <rect x="26" y="2" width="20" height="20" fill="#81BC06"></rect>
                <rect x="2" y="26" width="20" height="20" fill="#05A6F0"></rect>
                <rect x="26" y="26" width="20" height="20" fill="#FFBA08"></rect>
              </svg>
              <span className="text-sm font-semibold leading-6">Microsoft</span>
            </a>
          </div>

          <p className="mt-6 text-center text-sm text-gray-500">
            Already a member?{' '}
            <Link to="/" className="font-semibold leading-6 text-green-600 hover:text-green-600">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
