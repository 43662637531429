// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBQfSM0UCeC_Fd5_idQvIkDxd9DncY0z3E",
  authDomain: "atom-copilot.firebaseapp.com",
  projectId: "atom-copilot",
  storageBucket: "atom-copilot.appspot.com",
  messagingSenderId: "628143187906",
  appId: "1:628143187906:web:7f3931decb40765ad5d85b",
  measurementId: "G-DLSTF81MWG"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
