import { useState, useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Navbar from './navbar';
import MobileNav from './mobile';
import Notifications from './notifications';
import { useAuth } from './contexts/AuthContext';
import { db } from './firebase';
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Spinner from './spinner';
import { Radio, RadioGroup } from '@headlessui/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

// Simplified Pricing Plans Configuration
const plans = [
  /* { name: 'Free', features: 'Create 1 Model, 5 Analysis Queries, 5 Adjustment Queries, Model Validation', price: '$0 USD' }, */
  { name: 'Individual / Pro', features: 'Create 10 Models, Unlimited Analysis Queries, Unlimited Adjustment Queries, Model Validation, Priority Support', price: '$100 USD' },
  /* { name: 'Custom', features: 'Custom Number of Models, Unlimited Analysis Queries, Unlimited Adjustment Queries, Model Validation, Priority Support', price: 'Contact For Pricing' }, */
];

export default function Billing() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const { currentUser, logout } = useAuth();
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profilePhoto: null,
  });

  useEffect(() => {
    document.title = "Billing - Atom Copilot | AI-driven Financial Modeling Tool";
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      if (currentUser) {
        const docRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData({
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            email: data.email || '',
            profilePhoto: data.profilePhoto || null,
          });
        }
      }
      setTimeout(() => setIsLoading(false), 300);
    };
    fetchUserData();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error("Failed to log out:", err);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className="min-h-full">
        <Navbar
          screen="billing"
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          reloadTrigger={reloadTrigger}
          userData={userData}
        />
        <div className="flex flex-col lg:pl-64">
          <MobileNav sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} reloadTrigger={reloadTrigger} />
          <div className="h-full">
            <Header text="Billing" drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            <main className="min-h-screen col-span-2 flex-1 overflow-y-auto mr-4">
              <div className="flex-1 xl:overflow-y-auto mb-10">
                <div className="mt-6 px-4 sm:px-6 lg:px-8 min-h-screen">

                  {/* Simplified Pricing Plans Section */}
                  <div className="bg-white">
                    <h2 className="text-lg font-semibold leading-6 text-gray-900">Your Pricing Plan: Individual / Pro</h2>
                    <h2 className="mt-1 text-sm leading-6 text-gray-500">For more info on our pricing plans, visit <a href="mailto:support@atomcopilot.com" target="_blank" rel="noreferrer" className="text-green-600">atomcopilot.com</a> or <a href="https://atomcopilot.com" target="_blank" rel="noreferrer" className="text-green-600">contact us</a>.</h2>
                    <RadioGroup
                      value={selectedPlan}
                      onChange={setSelectedPlan}
                      className="mt-6 space-y-4"
                    >
                      {plans.map((plan) => (
                        <Radio
                          key={plan.name}
                          value={plan}
                          className="group relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm focus:outline-none data-[focus]:border-green-600 data-[focus]:ring-2 data-[focus]:ring-green-600"
                        >
                          <div className="flex justify-between">
                            <div>
                              <h3 className="font-medium text-sm text-gray-900">{plan.name}</h3>
                              <p className="mt-1 text-sm text-gray-500">{plan.features}</p>
                            </div>
                            <div className="text-sm text-gray-900 font-medium">
                              <span>{plan.price}</span>
                              <span className="ml-1 text-gray-500">/mo</span>
                            </div>
                          </div>
                          <span
                            aria-hidden="true"
                            className="pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent group-data-[focus]:border group-data-[checked]:border-green-600"
                          />
                        </Radio>
                      ))}
                    </RadioGroup>
                    <p className="mt-4 text-sm text-gray-600">Next Payment Due: October 15th, 2024</p>
                    <div className="flex justify-between gap-x-3 pt-8">
                      <div className="flex space-x-3">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                          Update Plan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Notifications reloadTrigger={reloadTrigger} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
          </div>
        </div>
      </div>

      {/* Success message */}
      {false && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-lg flex items-center" role="alert">
          <CheckCircleIcon className="w-5 h-5 text-green-700 mr-2" />
          <span>Billing information updated successfully!</span>
        </div>
      )}
    </>
  );
}
