// src/contexts/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  sendEmailVerification, 
} from 'firebase/auth';
import { db } from '../firebase'; // Firestore database
import { doc, getDoc, setDoc } from 'firebase/firestore'; // Firestore functions

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Check the provider data to determine if it's Microsoft
        const isMicrosoftSignIn = user.providerData.some(
          (provider) => provider.providerId === 'microsoft.com'
        );

        // Set current user
        setCurrentUser(user);

        // If signed in with Microsoft, mark as verified
        if (isMicrosoftSignIn) {
          setIsEmailVerified(true);
        } else {
          // Otherwise, use the actual email verification status
          setIsEmailVerified(user.emailVerified);
        }
      } else {
        setCurrentUser(null);
        setIsEmailVerified(false); // Reset verification status when user is not logged in
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function signup(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Reload the user to ensure the user object is fully initialized
      await user.reload();
  
      // Send verification email
      await sendEmailVerification(user);
      
      return userCredential;
    } catch (error) {
      console.error('Error sending verification email:', error.message);
      throw new Error('Failed to send verification email. Please try again.');
    }
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  // Create Account with Google
  async function createAccountWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      // Check if the user already exists in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      // If the user doesn't exist, create the document
      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          firstName: user.displayName?.split(' ')[0] || '',
          lastName: user.displayName?.split(' ')[1] || '',
          email: user.email,
          profilePhoto: user.photoURL || '',
          twoStepVerification: false,
          signInMethod: 'google',
          notifications: [
            {
              id: Date.now().toString(),
              title: 'Account Created',
              message: 'Your account has been successfully created with Google.',
              timestamp: new Date().toISOString(),
              read: false,
            },
          ],
          lastUpdated: new Date().toISOString(),
        });
      }

      return userCredential;  
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('This email is already in use. Please sign in instead.');
      } else {
        throw new Error('Failed to create an account with Google.');
      }
    }
  }

  // Create Account with Microsoft
  async function createAccountWithMicrosoft() {
    try {
      const provider = new OAuthProvider('microsoft.com');
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;

      console.log(user)
      console.log("USER SHOULD BE HERE")
      // Check if the user already exists in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      // If the user doesn't exist, create the document
      if (!userDocSnap.exists()) {
        await setDoc(userDocRef, {
          firstName: user.displayName?.split(' ')[0] || '',
          lastName: user.displayName?.split(' ')[1] || '',
          email: user.email,
          profilePhoto: user.photoURL || '',
          twoStepVerification: false,
          signInMethod: 'microsoft',
          notifications: [
            {
              id: Date.now().toString(),
              title: 'Account Created',
              message: 'Your account has been successfully created with Microsoft.',
              timestamp: new Date().toISOString(),
              read: false,
            },
          ],
          lastUpdated: new Date().toISOString(),
        });
      }

      return userCredential;  
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        throw new Error('This email is already in use. Please sign in instead.');
      } else {
        throw new Error('Failed to create an account with Microsoft.');
      }
    }
  }

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    isEmailVerified,
    createAccountWithGoogle,
    createAccountWithMicrosoft,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
