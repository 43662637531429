// src/components/RedirectRoute.js

import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';

export default function RedirectRoute() {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (currentUser) {
    // Redirect to dashboard if the user is authenticated
    return <Navigate to="/profile" replace state={{ from: location }} />;
  } else {
    // Redirect to sign in if the user is not authenticated
    return <Navigate to="/" replace state={{ from: location }} />;
  }
}