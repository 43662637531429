// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function PrivateRoute({ children }) {
  const { currentUser, isEmailVerified } = useAuth();

  return currentUser ? (isEmailVerified ? children : <Navigate to="/verify-email" />) : <Navigate to="/" />;
}
